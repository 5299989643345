import React, { useState } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import AboutImg from "../../../components/Image/AboutImg"
import {
  Title,
  SubTitle,
  Button,
  Select,
  Span,
  Textarea,
  MessageParagraph,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  { name: "ASCII and Binary", link: "/ascii-and-binary-converter/" },
]
const seeAlsoArray = [
  "/ascii-code-converter",
  "/binary-decoder",
  "/binary-encoder",
  "/morse-code-conversion",
  "/url-encoder-and-decoder",
]

function ASCIIandBinaryConverter(props) {
  function toBinary(input) {
    var result = ""
    for (var i = 0; i < input.length; i++) {
      var bin = input[i].charCodeAt().toString(2)
      result += Array(8 - bin.length + 1).join("0") + bin
    }
    return result
  }

  function toAscii(input) {
    var result = ""
    var arr = input.match(/.{1,8}/g)
    for (var i = 0; i < arr.length; i++) {
      result += String.fromCharCode(parseInt(arr[i], 2).toString(10))
    }
    return result
  }

  let [asciiOrBinary, setasciiOrBinary] = useState("1")

  let [binaryInput, setbinaryInput] = useState("")

  let [resultCode, setresultCode] = useState("")

  let [binaryError, setbinaryError] = useState("")

  function asciiOrBinaryC(e) {
    setasciiOrBinary(e.target.value)
  }
  function binaryInputC(e) {
    setbinaryInput(e.target.value)
  }

  function convert() {
    if (asciiOrBinary === "1") {
      if (binaryInput === "") {
        setbinaryError("Invalid ASCII")
        setresultCode("")
      } else if (binaryInput.match(/[^\u0000-\u007f]/)) {
        setbinaryError("Invalid ASCII")
        setresultCode("")
        setbinaryError("")
      } else {
        setresultCode(toBinary(binaryInput))
        setbinaryError("")
      }
    } else {
      if (binaryInput === "") {
        setbinaryError("Invalid Binary")
        setresultCode("")
      } else if (/\b[01]+\b/.test(binaryInput)) {
        setresultCode(toAscii(binaryInput))
        setbinaryError("")
      } else {
        setresultCode("")
        setbinaryError("Invalid Binary")
      }
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="ASCII To Binary, Binary to ASCII Conversion"
        description="ASCII To Binary or Binary to ASCII Conversion, Simple translator binary to ASCII and ASCII to binary conversion step by step."
        keywords={[
          "binary to ASCII, ASCII To Binary,binary code translator,binary encoder decoder, ASCII binary converter, ASCII converter,convert ASCII to binary, encoder binary,decoder binary,binary encoder,binary decoder, binary code, binary translator to ASCII.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>ASCII and Binary Conversion</Title>
        <SubTitle>Binary To ASCII and ASCII To Binary Online Tools</SubTitle>

        <Select maxWidth="200px">
          <select
            aria-label="tTA"
            value={asciiOrBinary}
            onBlur={asciiOrBinaryC}
            onChange={asciiOrBinaryC}
          >
            <option value="1">To Binary</option>
            <option value="0">To ASCII</option>
          </select>
        </Select>

        <br />
        <Span>Input Here To Conversion :</Span>
        <Textarea
          className="mb-3"
          type="text"
          value={binaryInput}
          onChange={binaryInputC}
        ></Textarea>
        <Button
          className="mb-3"
          onClick={convert}
          borderColor="#1da1f2"
          color="#1da1f2"
          hoverColor="white"
          hoverBorderColor="#1da1f2"
          hoverBackgroundColor="#1da1f2"
        >
          Convert
        </Button>
        <MessageParagraph color="red">{binaryError}</MessageParagraph>
        <Textarea value={resultCode} readOnly></Textarea>

        <br />
        <FlexDiv maxWidth="600px">
          <AboutImg filename="ascii.png" alt="ASCII Translator converter" />
        </FlexDiv>
        <br />
        <h3>ASCII To Binary!</h3>
        <p>
          Convert text to binary Step by step :<br /> Step one: enter ASCII
          code.
          <br /> Step 2: converted to Binary. <br /> simple math, with a little
          help from ASCII. <br /> In ASCII, there is a decimal assigned to each
          character.
        </p>

        <h3>Binary</h3>
        <p>
          Binary always begin with 010 or 011. <br /> "010" will indicate an
          uppercase letter, and "011" will indicate lowercase.
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default ASCIIandBinaryConverter
